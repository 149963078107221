import React, { useState, useEffect, useRef } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import recoveryHeading from '../images/recoverypunnkHeading.png'
import star from "../images/star.png";
import leftRectangleDot from "../images/leftRectangleDot.png"
import image3 from "../images/img3.png";
import image5 from "../images/Group 65.png";
import image6 from "../images/image6.png";
import rectanglecarosuel from "../images/Rectangle 104.png";
import rectangle from "../images/Rectangle 97.png";
import image7 from "../images/image7.png";
import RectangleforCarsouel from "../images/Group 57.png";
import wallectConnectSVG from "../images/Vector.svg";
import image18 from "../images/Group 37.png";
import image19 from "../images/Group 36.png";
import image20 from "../images/promo.gif";
import metamaskwallet from "../images/metamask-icon 1.png";

// import { FaDiscord } from "react-icons/fa";
import rectangledot4 from "../images/Group 4.png";
import rectangledot5 from "../images/Group 3.png";
import animate1 from "../images/2.png";
import animate2 from "../images/3.png";
import animate3 from "../images/4.png";
import animate4 from "../images/7.png";
import animate5 from "../images/8.png";
import animate6 from "../images/9.png";
import animate7 from "../images/22.png";
import animate8 from "../images/24.png";
import animate9 from "../images/32.png";
import animate10 from "../images/35.png";
import animate11 from "../images/39.png";
import animate12 from "../images/43.png";
import animate13 from "../images/9048.png";
import animate14 from "../images/9053.png";
import animate15 from "../images/9058.png";
import animate16 from "../images/9060.png";
import animate17 from "../images/9061.png";
import animate18 from "../images/9066.png";
import animate19 from "../images/9053.png";
import animate20 from "../images/9055.png";
import animate21 from "../images/9058.png";
import animate22 from "../images/9060.png";
import animate23 from "../images/9061.png";

import Image20 from "../images/Rectangle 8.png";
import NftImage from "../images/NFTImage.png"
import rectangle3 from '../images/FAQ.png';
import middleRectangle from '../images/MiddleReactangle.png';

// import { connectors } from './Connetors';
import walletconnect11 from "../images/Vector.png";


//  using modal 
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';



import rectangledot1 from "../images/recoverypunksNFT.png";

import AOS from "aos";
import "aos/dist/aos.css";

// web3 related imports
import MetaMaskOnboarding from "@metamask/onboarding";
import { useWeb3React } from "@web3-react/core";
import { injected, walletconnect } from "../utils/connector";
import Web3 from "web3";

import "./Navbar.css";

import useMinterContract from "../hooks/useMinterContract";
import useNftContract from "../hooks/useNFTContract";
import NFTAbi from "../contracts/NFT.json"
import NFTWalletBtn from "./NFTWalletBtn";


const style = {
  
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: '#1E1E1E',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function Navbar() {

  const { connector, account, activate, error, active, chainId, deactivate } =
  useWeb3React();
  
  

  const minterContract = useMinterContract();
  const nftContract = useNftContract();

  const [totalMinted, settotalMinted] = useState("0")
  const [round, setround] = useState("")
  const [price, setPrice] = useState(0);
  const [remainingNfts, setremainingNfts] = useState(0)
  console.log(remainingNfts , "line no 110")
  const [isdisable, setisDisable] = useState(false)

  const [activatingConnector, setActivatingConnector] = useState();
  const onboarding = useRef();

  const onConnectWithMetamaskClick = () => {
    if (MetaMaskOnboarding.isMetaMaskInstalled()) {
      setActivatingConnector(injected);
      activate(injected, undefined, true)
        .then(() => {
          //console.log("Connected successfully");
        })
        .catch(() => {
          // setTried(true);
        });
      localStorage.setItem("connectedWallet", "metamask");
    } else {
      onboarding.current.startOnboarding();
    }
  };

  const onConnectWithWalletConnect = () => {
    setActivatingConnector(walletconnect);
    activate(walletconnect, undefined, true).catch((err) => {
      setActivatingConnector();
      walletconnect.walletConnect1Provider = undefined;
      localStorage.removeItem("connectedWallet");
      if (err) {
        window.location.reload(false);
      }
    });
    localStorage.setItem("connectedWallet", "walletConnect");
  };

  useEffect(() => {
    if (!onboarding.current) {
      onboarding.current = new MetaMaskOnboarding();
    }
  }, []);

  useEffect(() => {
    if (MetaMaskOnboarding.isMetaMaskInstalled()) {
      if (account && account.length > 0) {
        onboarding.current.stopOnboarding();
      }
    }
  }, [account]);

  useEffect(() => {
    if (activatingConnector && activatingConnector === connector) {
      setActivatingConnector(undefined);
    }
  }, [activatingConnector, connector]);

  // const {
  //     library,
  //     chainId,
  //     account,
  //     activate,
  //     deactivate,
  //     active
  //   } = useWeb3React();
  const [count, setCount] = useState(0);   // state for  mint  count

  const [showModal, setShowModal] = useState(false);   // show  wallet modal
  // const [address, setAddress] = useState([])   //  state for  show  address id  on UI
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);






  useEffect(() => {
    async function fetchData() {
      let s = await nftContract.methods.totalSupply().call();
      settotalMinted(s)
    }
    fetchData();
  }, []);


  useEffect(() => {
    async function fetchData() {
      let r = await minterContract.methods.getRound().call();
      console.log("round", r);
      if (r == 0) {
        setround("Whitelist Round - on going")
      }
      else if (r == 1) {
        setround("Special NFT Round")
      }
      else if (r == 2) {
        setround("Public Round")
      }
    }
    fetchData();
  }, []);


  useEffect(() => {
    async function fetchData() {
      let p = await minterContract.methods.price().call();
      setPrice(Number(p) / 10 ** 18)
    }
    fetchData();
  }, []);

  useEffect(() => {
    async function fetchData() {
    let remainingcontri = await minterContract.methods.remainigContribution(account).call();
    let p = await minterContract.methods.price().call();
    let pricee = Number(p) / 10 ** 18
   
    const remainingnfts = (Number(remainingcontri) / 10 ** 18) / pricee;
    setremainingNfts(remainingnfts)
    }
    if (account) {
      fetchData();
    }
  },[account])


  const mint = async () => {
    try {
      setisDisable(true)
      await minterContract.methods.trade(count).send({
        from: account,
        value: Web3.utils.toWei((count * price).toString(), "ether"),
      });
      setisDisable(false)
      alert("Tx Success")
    }
    catch (e) {
      setisDisable(false)
      alert("Tx Fail")
      console.error("Error", e)
    }
  }

  // Using for Animation
  useEffect(() => {

    AOS.init(
      {
        duration: 1200,
      }
    );
  }, [])


  //   function for Drecrement  mint quantity
  const decrementCountHandler = () => {
    if (count === 0) {
      return 0;
    }
    setCount(count - 1);
  }

  const  IncrementCountHandler =()=>{
    if(count >= remainingNfts){
      return remainingNfts;
    }
    else{

      setCount(count + 1)
    }
  }


  return (
    <>
      {/* ------------------------------------------------------Navbar Section-------------------------------------------------- */}
      {/* <div className="w-[100vw] relative">
      </div> */}

       

        {/* -------------------------------------------for mobile view only  recovery punks ------------------------------ */}
        <div className="mobileView absolute hidden md:hidden lg:hidden xl:hidden  top-[20vh] ">
          {/* <img className="" data-aos="fade-up" src={image6} alt="bg-black" /> */}
          {/* <img className="" data-aos="fade-up" src={rectangle} alt="bg-black" /> */}
          {/* <img className="" data-aos="fade-up" src={image7} alt="bg-black" /> */}
        </div>

        <div className="RedText absolute top-32  left-0 right-0  md:hidden xl:hidden lg:hidden ">
          <div className="text-center content-center ">
            {/* <h1 className=" py-2 px-2 mx-5 text-[20px] font-[pixel] text-white tracking-widest font-normal bg-[#EF2938] ">
              WHITELIST MINT NOW
            </h1> */}
            {/* <br /> */}
             {/* <a href="#unlock">
            <button  className=" cursor-pointer py-2 px-2 mx-5 text-[20px] font-[pixel] text-white tracking-widest font-normal bg-[#EF2938] ">
              MINT NOW
            </button>
             </a> */}

             
            <br />
            {/* <h1 className=" py-2 px-2 mx-5 text-[20px] font-[pixel] text-white tracking-widest font-normal bg-[#EF2938] ">
              WHITELIST MINT NOW
            </h1> */}
          </div>
        </div>

        {/* <div className="relative md:hidden xl:hidden lg:hidden">
          <div className="Recoverypunks md:hidden xl:hidden lg:hidden absolute  left-0 right-0  ">
            <h1 className="mx-8  ">
              <span className="text-[30px] font-[pixel] text-white tracking-widest font-normal">
                RECOVERY
              </span>
              <span className="text-[30px]  font-[pixel] text-[#EF2938] tracking-widest font-normal ">
                PUNKS
              </span>
            </h1>
            <p className="mt-2 text-[14px] font-normal leading-5 text-white text-center pargraph px-3">
              Inspired by the original CryptoPunk project, the RecoveryPunks are 10,000 unique 24x24 pixel art images generated algorithmically. RecoveryPunks are the same price for everyone: .01 eth to mint. While they are all rare, some RecoveryPunks are rarer than others. 100% of mint profits and royalties go directly to funding addiction recovery and connection research.
            </p>
          </div>
        </div> */}

        {/* <div className="absolute grid grid-cols-2  left-0 right-0 mx-2 gap-2  mt-[20rem]  md:hidden xl:hidden lg:hidden">
          <div className="p-5 bg-[#EF2938] text-center">
            <h1 className="text-[14px] pargraph font-normal">
              Whitelist Mint{" "}
            </h1>
            <h1 className="text-[20px] text-black  font-normal leading-5 font-[pixel]">
              10 / 27 / 2022
            </h1>
            <p className="text-[12px] font-normal text-white pargraph">
              3000 spots at 0.128
            </p>
            <p className="text-[12px] font-normal text-white pargraph">
              Eth + gas.
            </p>
          </div>
          <div className="p-5 bg-[#EF2938] text-center">
            <h1 className="text-[14px] pargraph font-normal">
              Whitelist Mint{" "}
            </h1>
            <h1 className="text-[20px] text-black  font-normal leading-5 font-[pixel]">
              10 / 27 / 2022
            </h1>
            <p className="text-[12px] font-normal text-white pargraph">
              3000 spots at 0.128
            </p>
            <p className="text-[12px] font-normal text-white pargraph">
              Eth + gas.
            </p>
          </div>
          <div className="p-5 bg-[#EF2938] text-center">
            <h1 className="text-[14px] pargraph font-normal">
              Whitelist Mint{" "}
            </h1>
            <h1 className="text-[20px] text-black  font-normal leading-5 font-[pixel]">
              10 / 27 / 2022
            </h1>
            <p className="text-[12px] font-normal text-white pargraph">
              3000 spots at 0.128
            </p>
            <p className="text-[12px] font-normal text-white pargraph">
              Eth + gas.
            </p>
          </div>
          <div className="p-5 bg-[#EF2938] text-center">
            <h1 className="text-[14px] pargraph font-normal">
              Whitelist Mint{" "}
            </h1>
            <h1 className="text-[20px] text-black  font-normal leading-5 font-[pixel]">
              10 / 27 / 2022
            </h1>
            <p className="text-[12px] font-normal text-white pargraph">
              3000 spots at 0.128
            </p>
            <p className="text-[12px] font-normal text-white pargraph">
              Eth + gas.
            </p>
          </div>
          <div className="p-5 bg-[#EF2938] text-center">
            <h1 className="text-[14px] pargraph font-normal">
              Whitelist Mint{" "}
            </h1>
            <h1 className="text-[20px] text-black  font-normal leading-5 font-[pixel]">
              10 / 27 / 2022
            </h1>
            <p className="text-[12px] font-normal text-white pargraph">
              3000 spots at 0.128
            </p>
            <p className="text-[12px] font-normal text-white pargraph">
              Eth + gas.
            </p>
          </div>
          <div className="p-5 bg-[#EF2938] text-center">
            <h1 className="text-[14px] pargraph font-normal">
              Whitelist Mint{" "}
            </h1>
            <h1 className="text-[20px] text-black  font-normal leading-5 font-[pixel]">
              10 / 27 / 2022
            </h1>
            <p className="text-[12px] font-normal text-white pargraph">
              3000 spots at 0.128
            </p>
            <p className="text-[12px] font-normal text-white pargraph">
              Eth + gas.
            </p>
          </div>
        </div> */}

        {/*----------------------------------------------------------------- Desktop view  Recovery punks Section------------------------------------------------------------------- */}

        {/* <div className="desktopView  w-full absolute   bottom-[12rem] h-[3rem]">
          <img
            src={image6}
            className="  lg:w-full   md:w-fit md:h-fit "
            data-aos="fade-up"
            alt="bg-black"
          />
        </div> */}
          {/* <div className="absolute xl:top-[6rem] left-0 right-0  lg:top-16  md:top-0 top-5 lg:flex space-y-5 lg:space-y-0  xl:items-center lg:items-center  xl:justify-center lg:justify-center lg:w-full     ">
          </div> */}
            {/* <div data-aos="fade-right" className=" text-center   ">
              <h1 className="text-white text-lg md:text-[16px] lg:text-[22px] py-2 bg-red-500  xl:text[32px] font-[pixel] uppercase lg:mx-0    md:mx-32 px-4 ">
                Whitelist mint now
              </h1>
            </div> */}

              
            {/* <div data-aos="fade-left" className=" text-center  ">
              <h1 className="text-white text-lg md:text-[16px]  lg:text-[20px] py-2 bg-red-500  xl:text[32px] font-[pixel] uppercase mx-12 lg:mx-0 md:mx-32 px-4  ">
                whitelist mint now
              </h1>
            </div> */}
          

         

          {/* <div className="absolute gap-4  xl:gap-10 lg:gap-10   xl:px-32 px-5 lg:px-32  md:top-[22rem]  top-[20rem]  xl:top-[35rem] w-full  md:grid-cols-3 grid grid-cols-2  lg:grid-cols-3  xl:grid xl:grid-cols-3">
            <div
              data-aos="fade-down"
              className=" text-center  pt-5 lg:pt-12  xl:pt-12 xl:h-52 lg:h-52   bg-red-600 border-red-600 "
            >
              <h1 className="pargraph font-semibold   text-sm lg:text-lg  xl:text-lg ">
                Whitelist Mint
              </h1>
              <h1
                data-aos="fade-right"
                className="xl:text-3xl lg:text-3xl text-xl md:text-sm  font-[pixel] mt-2  font-bold"
              >
                10/27/2022
              </h1>
              <p data-aos="fade-left" className="text-[#FFFFFF] md:text-sm ">
                3000 spots at 0.128
              </p>
              <p className=" text-[#FFFFFF]  md:text-sm  lg:pb-0 md:pb-5">Eth + gas.</p>
            </div>
            <div
              data-aos="fade-up"
              className="  text-center  pt-5 lg:pt-12  xl:pt-12 xl:h-52 lg:h-52    bg-red-600 border-red-600  "
            >
              <h1 className="pargraph text-sm lg:text-lg xl:text-lg font-semibold">
                Persale Mint
              </h1>
              <h1
                data-aos="fade-up"
                className="xl:text-3xl lg:text-3xl text-xl md:text-sm  font-[pixel] mt-2  font-bold"
              >
                10/27/2022
              </h1>
              <p data-aos="fade-down" className="text-[#FFFFFF] md:text-sm ">
                3000 spots at 0.128
              </p>
              <p className="text-[#FFFFFF] md:text-sm  lg:pb-0 md:pb-5">Eth + gas.</p>
            </div>
            <div
              data-aos="fade-left"
              data-aos-easing="linear"
              data-aos-duration="1500"
              className="  text-center  pt-5 lg:pt-12  xl:pt-12 xl:h-52 lg:h-52   bg-red-600 border-red-600  "
            >
              <h1 className="pargraph font-semibold  lg:text-lg text-sm  xl:text-lg">
                The Final Open Mint Date
              </h1>
              <h1
                data-aos="fade-down"
                className="xl:text-3xl lg:text-3xl text-xl  md:text-sm   font-[pixel] mt-2  font-bold"
              >
                10/27/2022
              </h1>
              <p data-aos="fade-left" className="text-[#FFFFFF] md:text-sm ">
                3000 spots at 0.128
              </p>
              <p className=" text-[#FFFFFF] md:text-sm lg:pb-0 md:pb-5 ">Eth + gas.</p>
            </div>
            <div
              data-aos="zoom-in-right"
              className="  text-center  pt-5 lg:pt-12  xl:pt-12 xl:h-52 lg:h-52   bg-red-600 border-red-600  "
            >
              <h1 className="pargraph font-semibold  text-sm lg:text-lg xl:text-lg">
                Whitelist Mint
              </h1>
              <h1
                data-aos="fade-right"
                className="xl:text-3xl lg:text-3xl text-xl md:text-sm   font-[pixel] mt-2  font-bold"
              >
                10/27/2022
              </h1>
              <p data-aos="fade-left" className="text-[#FFFFFF] md:text-sm ">
                3000 spots at 0.128
              </p>
              <p className=" text-[#FFFFFF] md:text-sm  lg:pb-0 md:pb-5 ">Eth + gas.</p>
            </div>
            <div
              data-aos="zoom-in-up"
              className=" text-center  pt-5 lg:pt-12  xl:pt-12 xl:h-52 lg:h-52   bg-red-600 border-red-600  "
            >
              <h1 className="pargraph text-sm lg:text-lg xl:text-lg font-semibold">
                Presale Mint
              </h1>
              <h1
                data-aos="fade-down"
                className="xl:text-3xl lg:text-3xl text-xl  md:text-sm   font-[pixel] mt-2  font-bold"
              >
                10/27/2022
              </h1>
              <p data-aos="fade-up" className="text-[#FFFFFF] md:text-sm ">
                3000 spots at 0.128
              </p>
              <p className=" text-[#FFFFFF] md:text-sm lg:pb-0 md:pb-5 ">Eth + gas.</p>
            </div>
            <div
              data-aos="zoom-in-left"
              className="  text-center  pt-5 lg:pt-12  xl:pt-12 xl:h-52 lg:h-52   bg-red-600 border-red-600  "
            >
              <h1 className="pargraph text-sm lg:text-lg xl:text-lg font-semibold ">
                The Final Open Mint Date
              </h1>
              <h1
                data-aos="fade-left"
                className="xl:text-3xl lg:text-3xl text-xl md:text-sm    font-[pixel]  mt-2  font-bold"
              >
                10/27/2022
              </h1>
              <p data-aos="fade-down" className="text-[#FFFFFF]  md:text-sm  ">
                3000 spots at 0.128
              </p>
              <p className=" text-[#FFFFFF] md:text-sm lg:pb-0 md:pb-5 ">Eth + gas.</p>
            </div>
          </div> */}
          {/* <div className="">
            <img
              className="w-[75rem]  h-[30rem] lg:h-fit lg:w-full  "
              src={image7}
              alt="bg-black"
            />
          </div> */}
       
      

      {/* ------------------------------------------------What is a Recovery punks section----------------------------------------------------------- */}

      {/* <div
        data-aos="zoom-in"
        className=" xl:mt-[72rem]  mb-32 lg:mt-[45rem] lg:px-12 xl:px-20  mt-[60rem]  md:gap-12 lg:gap-24 xl:gap-0 md:mt-[38rem]   xl:items-center  md:flex xl:flex lg:w-full md:justify-between md:items-center lg:justify-between lg:items-center"
      >
        {/* <div data-aos="fade-right" className=" w-full ">
          <div className="mb-5 RacoveryPunksDot " >
            <img src={leftRectangleDot} alt="" />


          </div>
          <div className="RacoveryPunksDot text-center lg:text-left ">
            <h1 className="text-3xl md:[text-30px] xl:text-[96px]  lg:text-[56px] lg:leading-[82.05px]  xl:leading-[102.05px] font-bold tracking-widest font-[pixel] text-white ">
              WHAT iS
            </h1>
            <h1 className="text-3xl  md:text-[30px] xl:text-[96px] lg:text-[56px]   lg:leading-[82.05px] xl:leading-[102.05px]  font-bold tracking-widest font-[pixel] text-black uppercase">
              Recovery punks?
            </h1>
            <div className="flex md:justify-between items-center  md:pr-12 lg:pr-32">
              <img className="lg:pt-12   md:hidden " src={rectangledot5} alt='left-side-text' />
              <img className="md:hidden" src={rectangledot4} alt='left-side-text' />
            </div>

          </div>
        </div> */}

        {/* Mobile view  what is recovery punk heading */}
        {/* <div className="md:hidden  xl:hidden lg:hidden ">
          <img className="mx-auto" src={recoveryHeading} alt="recoveryHeading" />
        </div> */}

        {/* <div className="  px-5   ">
          <p className="lg:text-lg lg:tracking-wide lg:leading-8 xl:text-lg xl:tracking-wide xl:px-20 xl:leading-8 mb-5 text-center md:text-start xl:text-start lg:text-start tracking-normal text-white ">
            Inspired by the original CryptoPunk project, the RecoveryPunks are
            10,000 unique 24x24 pixel art images generated algorithmically.
          </p>
          <p className=" lg:text-lg lg:tracking-wide lg:leading-8 xl:text-lg xl:tracking-wide xl:leading-8  xl:px-20 text-center  md:text-start xl:text-start lg:text-start tracking-normal text-white ">
            RecoveryPunks are the same price for everyone: .01 eth to mint.
            While they are all rare, some RecoveryPunks are rarer than others.
          </p>
          <div className="mt-5  text-center md:text-start xl:px-20 ">
            <button className="text-white  font-[pixel] px-5 py-3 lg:text-2xl bg-black uppercase font-normal">
              know more
            </button>
          </div>
        </div> */}
     



      {/*----------------------------------------------------------------------------- NFT section------------------------------------- */}

      {/* ------------------------------------------------------for mobile version--------------------------------------------------------------------------- */}

      {/* <div className="mt-[32rem] relative  xl:hidden md:hidden lg:hidden xxl:hidden">
        <img src={image6} alt="bg-black" />
        <img className=" h-56 w-full" src={rectanglecarosuel} alt="bg-black" />
        <img src={image7} alt="" />
        <div className="absolute left-0 right-0 top-20  ">
          <img className="mx-auto" src={RectangleforCarsouel} alt="" />
          <h1
            data-aos="fade-right"
            className="   px-10  font-[pixel] text-[30px]  w-full text-center  font-bold uppercase  text-red-500"
          >
            RECOVERY PUNKS{" "}
            <span data-aos="fade-right" className="text-white font-bold">
              NFT
            </span>
          </h1>
        </div>

        <div className='absolute top-60'>
          <Carousel showThumbs={false} infiniteLoop={true} showArrows={true} showIndicators={true} showStatus={false} >
            <div className='flex  justify-center    '>
              <div className='space-y-2 '>
                <img className=' ' src={animate1} alt="" />
                <img className='' src={animate2} alt="" />
                <img className='' src={animate3} alt="" />
              </div>
              <div className='space-y-2'>
                <img className='' src={animate4} alt="" />
                <img className='' src={animate5} alt="" />
                <img className='' src={animate6} alt="" />
              </div>
              <div className='space-y-2'>
                <img className='' src={animate17} alt="" />
                <img className='' src={animate10} alt="" />
                <img className='' src={animate9} alt="" />
              </div>
            </div>
            <div data-aos="zoom-up" className='flex justify-center '>
              <div className='space-y-2'>
                <img className='' src={animate11} alt="" />
                <img className='' src={animate12} alt="" />
                <img className='' src={animate13} alt="" />
              </div>
              <div className='space-y-2'>
                <img className='' src={animate14} alt="" />
                <img className='' src={animate15} alt="" />
                <img className='' src={animate16} alt="" />
              </div>
              <div className='space-y-2'>
                <img className='' src={animate7} alt="" />
                <img className='' src={animate11} alt="" />
                <img className='' src={animate9} alt="" />
              </div>
            </div>
            <div data-aos="zoom-down" className='flex justify-center '>
              <div className='space-y-2'>
                <img className=' h-28' src={animate11} alt="" />
                <img className='' src={animate22} alt="" />
                <img className='' src={animate19} alt="" />
              </div>
              <div className='space-y-2'>
                <img className='' src={animate4} alt="" />
                <img className='' src={animate5} alt="" />
                <img className='' src={animate6} alt="" />
              </div>
              <div className='space-y-2'>
                <img className='' src={animate17} alt="" />
                <img className='' src={animate21} alt="" />
                <img className='' src={animate20} alt="" />
              </div>
            </div>
          </Carousel>
        </div>
      </div> */}

      {/*------------------------------------------------ For desktop version--------------------------------------------------------------------------------------- */}
      {/* <div className="CarosuelBAckground w-full relative mt-12 lg:mt-20  md:mt-0">
        <img
          className="w-full lg:h-fit xl:w-full lg:w-full xl:h-fit"
          src={image6}
          alt="bg-black"
        />
         </div> */}

    {/* <a  href="#unlock" className="absolute text-white top-1  left-0 right-0    bg-red-600  " >
     <h1 className="text-center  mx-0 font-[pixel] text-[30px] border   leading-5 text-normal ">
     MINT NOW
     </h1>

    </a> */}

 
       
     

      {/* ------------------------------------------------What is a Recovery punks section----------------------------------------------------------- */}

      {/* for mobile view only */}
      {/* <div className="mt-5  flex justify-center   xl:hidden md:hidden lg:hidden xxl:hidden">
        <img className="w-72  h-32 " src={rectangledot1} alt="rectangle" />
      </div> */}

      {/* desktop view of recovery punk  */}




      <div className="mx-auto  bg-[#EF2938] pt-4  xl:py-[8rem] md:py-[8rem] ">
     
        <div className="RacoveryPunksDot flex   justify-center items-center">
          <img
            className="RacoveryPunksDot  md:hidden"
            src={image18}
            alt="left-side-text"
          />
          <img
            className="RacoveryPunksDot"
            src={image19}
            alt="left-side-text"
          />
        </div>
        <div className="RacoveryPunksDot text-center  md:mt-[5rem] xl:mt-[8rem]">
          <h1
         
            className="lg:text-[56px] xl:text-[96px] md:text-[46px] tracking-wide leading-8 mb-5 text-white uppercase font-[pixel] "
          >
            Buy your <span className="text-black">recovery </span>{" "}
          </h1>
          <h1
         
            className="lg:text-[56px] xl:text-[96px]  md:text-[46px]  text-black uppercase font-[pixel]"
          >
            punks nft
          </h1>
        </div>

        {showModal ? (
          <>
          {/* <Button onClick={handleOpen}>Open modal</Button> */}
     


            <div

              className="justify-center   hidden fixed items-center   inset-0 z-50 outline-none focus:outline-none"
            >
              <div className=" relative  bg-[#EF2938]   mx-auto lg:max-w-3xl">
                {/* <button
                  className="CancelBtn  ml-auto  border-0    float-right   pt-2 lg:text-lg leading-none font-semibold outline-none focus:outline-none"
                  onClick={() => setShowModal(false)}
                >
                  <span className="   text-white  pr-2  lg:text-lg">
                    x
                  </span>
                </button> */}
                {/*content*/}
                <div className="relative border-0 shadow-lg  flex flex-col  lg:mt-2 lg:mb-2 xl:mt-2 xl:mb-2 lg:w-full xl:w-full bg-[#1E1E1E] outline-none focus:outline-none">
                  {/*header*/}
                  <div className="flex items-center text-center  xl:justify-center lg:justify-between justify-center   px-5 py-5 border-b border-solid border-slate-200 ">
                    <h3 className="lg:text-[30px]  text-white   font-[pixel] font-semibold">
                      SELECT PROVIDERS
                    </h3>

                  </div>
                  {/*body*/}
                  <div className="  relative p-6 flex-auto">
                    <div className="grid lg:grid-cols-2 gap-2  lg:w-[25rem] xl:w-[25rem]   ">
                      {/* <div className=" text-center  w-f cursor-pointer  py-12 px-5   pt-2  items-center ">
                        {" "}
                        <div className="bg-[#EF2938]  rounded-3xl p-3">
                          <button onClick={() => {
                            onConnectWithMetamaskClick();
                            setShowModal(false);
                          }} >

                            <img
                              className="mx-auto"
                              src={metamaskwallet} alt="metamask"
                            />
                          </button>

                        </div>

                        <div>
                          <button
                            onClick={() => {
                              onConnectWithMetamaskClick();
                              setShowModal(false);
                            }}
                            className="  leading-5 font-[pixel]  text-white  mt-4 bg-none uppercase "
                          >
                            Meta Mask
                          </button>
                        </div>
                      </div> */}

                      {/* <div className=" text-center rounded-lg cursor-pointer  py-12  px-5    pt-2  items-center ">
                        {" "}
                        <div className=" bg-[#EF2938] p-5 rounded-3xl ">
                          <button onClick={() => {
                            onConnectWithWalletConnect();
                            setShowModal(false);
                          }} >

                            <img
                              className="mx-auto  pt-5"

                              src={walletconnect11}
                              alt="WalletConnect"
                            />
                          </button>

                        </div>

                        <div>
                          <button
                            onClick={() => {
                              onConnectWithWalletConnect();
                              setShowModal(false);
                            }}
                            className=" w-full  font-[pixel] leading-5  text-white uppercase   bg-none mt-5 "
                          >
                            Wallet Connect
                          </button>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
        
            {/* <div className="opacity-25 fixed inset-0 z-40 bg-black"></div> */}
          </>
        ) : null}

        <div   id="unlock"  className="lg:mt-[5rem] md:flex xl:flex  md:items-center  xl:justify-between md:justify-between  md:gap-5  xl:gap-32  xl:items-center lg:items-center xl:px-32 lg:px-5  md:px-5">
          <div className="xl:px-16   ">
            <img
         
              className="rounded-3xl lg:p-2 "
              src={image20}
              alt="gif"
            ></img>
          </div>
          <div
     
            className="  mt-5 p-4 lg:pr-0  lg:mt-2 xl:mt-0  md:w-1/2 "
          >
            <h1 className="font-[pixel] text-center xl:text-start md:text-start lg:text-start text-lg lg:text-[36px] xl:text-[50px] text-white">
              RECOVERY PUNKS{" "}
            </h1>
            <h1 className="lg:text-[36px] text-white xl:text-start md:text-start lg:text-start text-center  font-[pixel]  xl:text-[50px] ">
              MINTED:{" "}{totalMinted}
            </h1>

            {account ? (
              <h1 className=" lg:text-[17px]  text-[11px] xl:text-[20px]  md:text-start text-center text-black font-[pixel] uppercase ">
                {account}
              </h1>
            ) : null}

            <p className="lg:text-start lg:mt-5 text-center xl:text-start text-white md:text-start lg:tracking-wide lg:leading-8 xl:leading-8 text-sm lg:text-lg xl:text-lg">
            Mint your RecoveryPunks by connecting your wallet, entering the number of RecoveryPunks you want to mint and pushing the mint button. 

            </p>
            <p className=" lg:text-start text-center px-5 md:px-0 xl:text-start text-white xl:px-0  md:text-start lg:px-0  text-sm  lg:tracking-wide lg:leading-8 xl:leading-8  lg:text-lg xl:text-lg">
          
            All proceeds go directly to Artists for Addicts and Genius Recovery to fund addiction recovery and connection research.

            </p>
            <p className="lg:text-start text-center xl:text-start text-white md:text-start lg:tracking-wide lg:leading-8 xl:leading-8 text-sm lg:text-lg xl:text-lg">
            Marketplace royalties are set at 10% until the entire collection is minted, in which case the royalties will be dropped to 2.5%
            </p>

            <p className="lg:text-start   text-center xl:text-start  text-black font-[pixel]   md:text-start lg:tracking-wide lg:leading-8 xl:leading-8 text-sm lg:text-lg xl:text-lg">
              Round:{" "}{round}
            </p>
            <p className="lg:text-start   text-center xl:text-start  text-black    md:text-start lg:tracking-wide lg:leading-8 xl:leading-8 text-sm lg:text-lg xl:text-lg">
             <span className="font-[pixel] text-[10px] lg:text-[16px] ">NFT Address:{" "} </span>  <span className="text-black font-[pixel] text-[10px]  lg:text-[16px] ">
            <a target="/blank" href="https://etherscan.io/address/0x66d921E88ebf0e58994827D732C24E7f1563fcE9">
             {process.env.REACT_APP_NFT_ADDRESS }
             </a>
             </span>
            </p>

            {account ? (
              <div className="flex gap-2  md:px-0  justify-center  xl:justify-start md:justify-start  items-center mt-[2rem]">
                <button
                  onClick={decrementCountHandler}
                  className=" border  hover:scale-110 hover:duration-300  hover:ease-linear duration-300 ease-linear  hover:bg-red-500 hover:text-white px-2 md:px-5 py-1 bg-black  font-semibold text-white   "
                >
                  -
                </button>
                <p className="border px-3 md:px-6 lg:px-12 py-1">{count}</p>
                <button
                  onClick={IncrementCountHandler}
                  className=" border  hover:scale-110 hover:duration-300  hover:ease-linear duration-300 ease-linear px-2 md:px-5 py-1 bg-black  hover:bg-teal-500 hover:text-white font-semibold text-white   "
                >
                  +
                </button>
                <button
                  onClick={()=>setCount(remainingNfts)}
                  className=" border  px-5 md:px-5 py-1 bg-black  font-semibold text-white   "
                >
                  Max
                </button>
                <button disabled={isdisable} className="border px-5 py-1  bg-black  animate-pulse uppercase font-semibold text-white " onClick={() => mint()} >
                  {" "}
                  Mint
                </button>
              </div>
            ) : (
              <div    className=" mt-5 xl:mt-0 lg:mt-0 text-center xl:text-start md:text-start  ">
                <Button 
             
                
                  onClick={handleOpen}>
                  <span className="hover:bg-blue-600 hover:ease-linear hover:scale-105 ease-linear rounded-lg hover:duration-100 bg-[#1E1e1e]  text-white   p-3">

                  Unlock Wallet
                  </span>
                </Button>
                <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box   sx={style}>
          <Typography  sx={{display:"flex" , justifyContent:"center" }}  id="modal-modal-title" variant="h6" component="h2">
          <h3 className="lg:text-[30px]  text-white    font-[pixel] font-semibold">
                      SELECT PROVIDERS
                    </h3>
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          <div className="  relative p-6 flex-auto">
                    <div className="grid md:grid-cols-2 gap-2  px-28 md:px-5 md:w-full    ">
                      <div className=" text-center  hover:scale-105 ease-in-out hover:ease-in-out hover:duration-150 duration-150 hover:border rounded-lg cursor-pointer  py-12 px-5   pt-2  items-center ">
                        {" "}
                        <div className="bg-[#EF2938]  rounded-3xl p-3">
                          <button onClick={() => {
                            onConnectWithMetamaskClick();
                            setShowModal(false);
                          }} >

                            <img
                              className=""
                              src={metamaskwallet} alt="metamask"
                            />
                          </button>

                        </div>

                        <div>
                          <button
                            onClick={() => {
                              onConnectWithMetamaskClick();
                              setShowModal(false);
                            }}
                            className="  leading-5 font-[pixel]  text-white  mt-4 bg-none uppercase "
                          >
                            Meta Mask
                          </button>
                        </div>
                      </div>

                      <div className=" text-center rounded-lg cursor-pointer hover:scale-105 ease-in-out hover:ease-in-out hover:duration-150 duration-150 hover:border  py-12  px-5    pt-2  items-center ">
                        {" "}
                        <div className=" bg-[#EF2938] p-5 rounded-3xl ">
                          <button onClick={() => {
                            onConnectWithWalletConnect();
                            setShowModal(false);
                          }} >

                            <img
                              className="  pt-5"

                              src={walletconnect11}
                              alt="WalletConnect"
                            />
                          </button>

                        </div>

                        <div>
                          <button
                            onClick={() => {
                              onConnectWithWalletConnect();
                              setShowModal(false);
                            }}
                            className=" w-full  font-[pixel] leading-5  text-white uppercase   bg-none mt-5 "
                          >
                            Wallet Connect
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
          </Typography>
        </Box>
      </Modal>
              </div>
            )}

            {/*  */}
          </div>
        
        </div>

     
      </div>
      
    </>
  )
}

export default Navbar
