import React from 'react'
import uperrShadow from '../images/image6.png'
import downShadow from '../images/image7.png'
import dotretangle from '../images/Group 30.png'
import umbrellaImg from '../images/Rectangle 10.png'
import purpleArrow from '../images/purpleImg.png'
import rectangleDot from '../images/Group 33.png'
import rectangle from '../images/Rectangle.png'

import image from '../images/Group 50.png'
import image6 from '../images/image6.png'
import rectanglecarosuel from '../images/Rectangle 104.png'
import rectangdot3 from '../images/Group 57.png'
import bgpurpleformob from '../images/Group 60.png'
import remote from '../images/remoter.png'
import blackrectangle from '../images/Rectangle 97.png'
import image7 from '../images/image7.png'
import vectorline from '../images/Vector 22.png'
import NFTimage from '../images/NFTImage.png'
import AttributesImage from '../images/AttributesImage.png'
import './Navbar.css'




function Hero() {
    return (
        <>

            {/* -----------------------------------------------mobile version for Artifacts Library ------------------------------ --------------------- */}
{/* 
            <div className='mt-20 relative  xl:hidden md:hidden lg:hidden xxl:hidden'>
            <div>
                <img  src={image6} alt='bg-black' />

            </div>
                <img  className='  w-full' src={rectanglecarosuel} alt='bg-black' />
                <img src={image7} alt='' />
                <div className='absolute top-10 left-0 right-0  '>
                    <img className='mx-auto' src={rectangdot3} alt="" />
                    <div className='text-center text-[30px] text-white font-normal font-[pixel] mt-2'>
                        <h1>ARTIFACTS</h1>
                        <h1>LIBRARY</h1>
                    </div>
                    <div>
                        <img className='w-full h-52 mx-auto px-5' src={NFTimage} alt='umbrellaImg' />
                    </div>
                    <div className='mt-5 px-5'>
                        <p className='text-[14px] leading-[21px] text-center text-white  pargraph ' >Find weapons, tools, potions, and sacred hieroglyphic spells to powerup Character NFTs in the Artifact Library.</p>
                        <p className='text-[14px] leading-[21px] text-center text-white  pargraph mt-5 ' >The seven secret societies provide access to different Artifacts. Rarity of Character NFTs increase when paired with special Artifacts, creating the perfect combination of Character and Artifact.</p>
                    </div>
                </div>
            </div> */}

            {/*------------------------------------------------------- for tablet view --------------------------------------------*/}
{/* 
<div className='TabletArtifactLibrary lg:hidden     xl:hidden mt-[5rem]'>
<div className='  relative '>
                <img className='  w-full' src={uperrShadow} alt='bg-black' />
                <div className='absolute top-52   flex   gap-12 px-12   justify-center items-center  font-semibold tracking-wider  w-full'>
                    <div className='lg:w-1/2'>
                        <img  className=' md:w-1/2   lg:w-fit'  src={dotretangle} alt='dotreactangle' />
               
                        <div className='flex gap-5 items-center   mt-5'>
                        <h1 className='uppercase font-[pixel] text-white   text-2xl  '>
                            Artifact
                        </h1>
                        <h1 className='uppercase font-[pixel]  text-white  text-2xl  '>
                            Library
                        </h1>
                        </div>

                        <p className='text-white mt-2 text-start   text-[12px] font-normal pargraph'>Find weapons, tools, potions, and sacred hieroglyphic spells to powerup Character NFTs in the Artifact Library.
                        </p>
                        <p className='text-white mt-5 text-start text-[12px] font-normal pargraph'>The seven secret societies provide access to different Artifacts. Rarity of Character NFTs increase when paired with special Artifacts, creating the perfect combination of Character and Artifact.
                        </p>


                    </div>
                    <div className='lg:w-1/2'>
                        <img className='lg:w-full lg:h-76' src={umbrellaImg} alt='umbrellaImg' />
                    </div>
                </div>
            </div>
     <div className='  w-full'>
                <img className='w-full' src={downShadow} alt='bg-black' />
            </div>
</div> */}




            {/* ----------------------------------------------------Desktop View Artifact Library-------------------------------------------------- */}
            {/* <div className='mt-[5rem] Atrifact   relative '>
                <img className='  w-full' src={uperrShadow} alt='bg-black' />
                <div className='absolute top-12 lg:gap-10  lg:top-0 xl:top-52 flex mt-52 px-12  xl:px-52 xl:gap-20  justify-center  font-semibold tracking-wider  w-full'>
                    <div className='lg:w-1/2'>
                        <img  className=' md:w-1/2   lg:w-fit'  src={dotretangle} alt='dotreactangle' />
               
                        <div>
                        <h1 className='uppercase font-[pixel] text-white mt-[4rem]  text-lg lg:text-[56px] xl:text-[96px] '>
                            Artifact
                        </h1>
                        <h1 className='uppercase font-[pixel] mt-16  text-white  text-lg lg:text-[56px] xl:text-[96px] '>
                            Library
                        </h1>
                        </div>

                        <p className='text-white  mt-10 text-start font-normal pargraph'>Find weapons, tools, potions, and sacred hieroglyphic spells to powerup Character NFTs in the Artifact Library.
                        </p>
                        <p className='text-white mt-5  text-start font-normal pargraph'>The seven secret societies provide access to different Artifacts. Rarity of Character NFTs increase when paired with special Artifacts, creating the perfect combination of Character and Artifact.
                        </p>


                    </div>
                    <div className='lg:w-1/2'>
                        <img className='lg:w-full lg:h-76' src={umbrellaImg} alt='umbrellaImg' />
                    </div>
                </div>
            </div>
            <div className='  w-full  Atrifact  '>
                <img className='w-full' src={downShadow} alt='bg-black' />
            </div> */}


            {/*  -----------------------------for mobile view  NFT Trading Game -------------------------------------------- */}


            {/* <div className='mt-12   xl:hidden md:hidden lg:hidden xxl:hidden '>
                <img className='mx-auto' src={rectangdot3} alt="" />
                <div className='text-center text-[30px] text-black font-normal font-[pixel] mt-2'>
                    <h1>NFT TRADING</h1>
                    <h1 className='text-white'>GAME</h1>
                </div>

                <div className='relative mt-5'>
                    <img className='mx-auto' src={bgpurpleformob} alt="" />

                    <div className='absolute top-8 right-5'>
                        <img src={remote} alt="" />
                    </div>

                    <div className='mt-5 px-5'>
                        <p className='text-[14px] leading-[21px] text-center text-white  pargraph ' >Book of Gates was created by artist, writer and explorer Alexander Black, who has conducted research expeditions throughout Egypt and ancient Petra to unlock the ancient mysteries found in the Book of Gates. Alexander Black is also the host of an upcoming docuseries “Search for the Real Atlantis.”


                        </p>
                        <p className='text-[14px] leading-[21px] text-center text-white  pargraph mt-5 ' >mysteries found in the Book of Gates. Alexander Black is also the host of an upcoming docuseries “Search for the Real Atlantis.”</p>
                    </div>

                </div>


            </div> */}


{/* ------------------------------------------for tab view only-------------------------------------------- */}


{/* <div className='NFTtabletView mt-32 lg:hidden xl:hidden   '>
                <img className='mx-auto' src={rectangdot3} alt="" />
                <div className='text-center text-[56px] text-black font-normal font-[pixel] mt-2'>
                    <h1>NFT TRADING</h1>
                    <h1 className='text-white'>GAME</h1>
                </div>

                <div className='relative flex  px-12  gap-12  w-full mt-5'>
                <div className='relative w-1/2  '>
                    <img className='mx-auto  h-full' src={bgpurpleformob} alt="" />
                    <div className='absolute top-16  left-52 right-0'>

                        <img className='mx-auto' src={remote} alt="" />
                    </div>

                   </div>

                    <div className='mt-5 w-1/2'>
                        <p className='text-[12px]  text-start text-white  pargraph ' >Book of Gates was created by artist, writer and explorer Alexander Black, who has conducted research expeditions throughout Egypt and ancient Petra to unlock the ancient mysteries found in the Book of Gates. Alexander Black is also the host of an upcoming docuseries “Search for the Real Atlantis.”


                        </p>
                        <p className='text-[12px]  text-start text-white  pargraph mt-5 ' >mysteries found in the Book of Gates. Alexander Black is also the host of an upcoming docuseries “Search for the Real Atlantis.”</p>
                    </div>

                </div>


            </div> */}





            {/*  -----------------------------Desktop view  NFT Trading Game -------------------------------------------- */}

            {/* <div className='NFTtradingGame flex  px-12  items-center xl:px-28 justify-between  lg:w-full  mt-[5rem]    gap-20'>
                <div data-aos="fade-up"
                    data-aos-easing="linear"
                    data-aos-duration="2000" className='xl:w-30%  lg:w-1/2   NFTtradingGame  '>
                    <img className='rounded-3xl h-[35rem]' src={purpleArrow} alt='purplearrow' />
                </div>
                <div data-aos="fade-down"
                    data-aos-easing="linear"
                    data-aos-duration="1500" className='xl:w-[70%]  w-1/2 NFTtradingGame  '>
                    <img className='lg:w-full lg:h-16  xl:w-fit xl:h-fit' src={rectangleDot} alt='rectangleDot' />
                    <h1 className='  texgt-lg xl:text-[80px] lg:text-[36px] mt-2  tracking-widest font-bold uppercase  font-[pixel]'>
                        <span className='text-white font-semibold tracking-widest'>NFT </span>Trading
                    </h1>
                    <h1 className=' text-lg lg:text-[36px]  xl:text-[80px]    tracking-wider  font-normal uppercase font-[pixel]'>
                        game
                    </h1>


                    <p className='text-lg xl:text-lg lg:text-sm pargraph leading-relaxed  mt-5 text-start  text-white'>Book of Gates was created by artist, writer and explorer Alexander Black, who has conducted research expeditions throughout Egypt and ancient Petra to unlock the ancient mysteries found in the Book of Gates. Alexander Black is also the host of an upcoming docuseries “Search for the Real Atlantis.”

                    </p>
                    <p className='text-lg xl:text-lg pargraph lg:text-sm  mt-5 text-start text-white'>
                        mysteries found in the Book of Gates. Alexander Black is also the host of an upcoming docuseries “Search for the Real Atlantis.”</p>
                </div>
            </div> */}

                 

            {/* -------------------------------------------------------------------- mobile view of Attributes------------------------------------------------------------------- */}


            

            {/* <div className='mt-20 relative  xl:hidden md:hidden lg:hidden xxl:hidden'>
                <img src={image6} alt='bg-black' />
                <img className='' data-aos="fade-up" src={blackrectangle} alt='bg-black' />
                <img src={image7} alt='' />
                <div className='absolute top-10 left-0 right-0  '>
                    <img className='mx-auto' src={rectangdot3} alt="" />
                    <div className='text-center text-[30px] text-white font-normal font-[pixel] mt-2'>
                        <h1>Attributes</h1>
                        <div className=' px-5'>
                        <p className='text-[14px] leading-[21px] text-center text-white  pargraph' >The Book of Gates Character NFTs derive from over 1.2 million attribute combinations from the stunning universe of the graphic novel. Each NFT will be a unique, randomly programed cell containing:</p>
                        </div>

                    </div>
                    <div className='mt-5'>
                        <img className=' mx-auto px-5' src={AttributesImage} alt='umbrellaImg' />
                    </div>
                    <div className='grid grid-cols-2 mt-12 px-5 gap-5'>
                    <div>
                        <h1 className='text-[20px]  font-[pixel] leading-[21px] text-white text-normal '>
                        Characters 
                        </h1>
                     <p className='text-[14px] leading-[21px]  text-normal text-white pargraph texgt-white '>28 different character and creature design.</p>
                    </div>
                    <div>
                        <h1 className='text-[20px]  font-[pixel] leading-[21px] text-white text-normal '>
                        ORDERS
                        </h1>
                     <p className='text-[14px] leading-[21px]  text-normal text-white pargraph texgt-white '>7 secret societies to belong to.</p>
                    </div>
                    <div>
                        <h1 className='text-[20px]  font-[pixel] leading-[21px] text-white text-normal '>
                        BACKGROUND
                        </h1>
                     <p className='text-[14px] leading-[21px]  text-normal text-white pargraph texgt-white '>7 secret societies to belong to.</p>
                    </div>
                    <div>
                        <h1 className='text-[20px]  font-[pixel] leading-[21px] text-white text-normal '>
                        Secret Codes
                        </h1>
                     <p className='text-[14px] leading-[21px]  text-normal text-white pargraph texgt-white '>7 secret societies to belong to.</p>
                    </div>
                    <div>
                        <h1 className='text-[20px]  font-[pixel] leading-[21px] text-white text-normal '>
                        Gates
                        </h1>
                     <p className='text-[14px] leading-[21px]  text-normal text-white pargraph texgt-white '>7 secret societies to belong to.</p>
                    </div>
                    </div>
                    
                </div>
            </div> */}











            {/* -------------------------------------------------------------------- Desktop view of Attributes Section-------------------------------------------------------------------
            ------------------------------------------------------------------------------------------------------------------------------------------- */}

            {/* <div className='attributes md:mt-[5rem]  relative '>
                <img className='  w-full' src={uperrShadow} alt='bg-black' />
                <div className='absolute  lg:top-5 md:top-8    xl:top-5 flex md:mt-20 lg:mt-40   xl:px-32  px-12 gap-10 xl:gap-20  justify-center  font-semibold tracking-wider  w-full'>
                    <div data-aos="fade-right" className='w-1/2'>
                        <img src={dotretangle} alt='dotreactangle' />
                        <h1 className='uppercase font-[pixel] text-white xl:mt-[4rem]  md:text-[30px] mt-5 lg:text-[56px] xl:text-[96px] '>
                            attributes
                        </h1>


                        <p className='text-white lg:mt-5 mt-2 text-start font-normal 2xl:text-lg text-sm pargraph md:text-[12px] '>The Book of Gates Character NFTs derive from over 1.2 million attribute combinations from the stunning universe of the graphic novel. Each NFT will be a unique, randomly programed cell containing:
                        </p>



                        <div className='flex   items-center gap-10 mt-5 xl:mt-[2rem]'>
                            <div className='w-1/2 text-white'>
                                <h1 className='xl:text-2xl lg:text-lg text-sm  font-normal font-[pixel] uppercase  '>Characters</h1>
                                <p className='xl:text-lg lg:text-sm md:text-xs pargraph font-light  text-start'>28 differnet character and creature design.</p>
                            </div>
                            <div className='w-1/2 text-white'>
                                <h1 className='xl:text-2xl lg:text-lg text-sm font-normal font-[pixel] uppercase  '>orders</h1>
                                <p className='text-lg lg:text-sm  pargraph   md:text-xs font-light text-start'>7 secret societies to belong to.</p>
                            </div>
                        </div>
                        <div className='flex gap-10  mt-[2rem]'>
                            <div className='w-1/2 text-white'>
                                <h1 className='xl:text-2xl lg:text-lg text-sm font-normal font-[pixel] uppercase  '>bakcgrounds</h1>
                                <p className='text-lg   lg:text-sm pargraph  md:text-xs font-light  text-start'>Taken from the graphic novel.</p>
                            </div>
                            <div className='w-1/2 text-white'>
                                <h1 className='xl:text-2xl lg:text-lg text-sm font-normal font-[pixel] uppercase  '>secret codes</h1>
                                <p className='text-lg lg:text-sm  pargraph   md:text-xs font-light text-start'>Hidden thorughtout.</p>
                            </div>
                        </div>

                        <div className='w-1/2 text-white  md:mt-[1.5rem] lg:mt-[2rem]'>
                            <h1 className='xl:text-2xl lg:text-lg font-normal text-sm font-[pixel] uppercase  '>gates</h1>
                            <p className='text-lg   lg:text-sm pargraph font-light  md:text-xs  text-start'>7 secret societies to belong to.</p>
                        </div>

                    </div>
                    <div data-aos="fade-left" className='w-1/2'>
                        <img className='h-fit w-fit lg:h-[85%] lg:w-full xl:w-full xl:h-full ' src={rectangle} alt='umbrellaImg' />
                    </div>
                </div>
            </div>
            <div className=' w-full  attributes'>
                <img className='w-full' src={downShadow} alt='bg-black' />
            </div> */}



            {/* --------------------------------------------------------------------------mobile version  of Roadmap section----------------------------------------------------------------------- */}
         
{/*          
            <div className='mt-12   xl:hidden md:hidden lg:hidden xxl:hidden '>
                <img className='mx-auto' src={rectangdot3} alt="" />
                <div className='text-center text-[30px] text-black font-normal leading-[32px] font-[pixel] mt-2'>
                    <h1 className='text-white uppercase'>Roadmap</h1>
                    <h1 className='uppercase'>Activation</h1>
                </div>
                <div className='relative  z-10'>
                    <div className=' w-1/2 text-right pt-5 px-2'>
                        <h1 className='text-[20px]  font-[pixel] leading-[21px] text-white text-normal '>
                        10% - Cost Management
                        </h1>
                     <p className='text-[14px] leading-[21px]  text-normal text-white pargraph texgt-white '>28 different character and creature design.</p>
                    </div>

                    <div className='flex justify-end' >
                    <div className=' w-1/2 text-left pt-5 pl-2'>
                        <h1 className='text-[18px] uppercase font-[pixel] leading-[21px] text-white text-normal '>
                        30% - The Ogdoad Characters
                        </h1>
                     <p className='text-[14px] leading-[21px]  text-normal text-white pargraph texgt-white '>28 different character and creature design.</p>
                    </div>
                    </div>
                    <div className=' w-1/2 text-right pt-5 px-2'>
                        <h1 className='text-[20px]  font-[pixel] leading-[21px] text-white text-normal '>
                        60% - Merch Store
                        </h1>
                     <p className='text-[14px] leading-[21px]  text-normal text-white pargraph texgt-white '>28 different character and creature design.</p>
                    </div>

                    <div className='flex justify-end' >
                    <div className=' w-1/2 text-left pt-5 pl-2'>
                        <h1 className='text-[18px] uppercase font-[pixel] leading-[21px] text-white text-normal '>
                        80% - Treasure Hunt
                        </h1>
                     <p className='text-[14px] leading-[21px]  text-normal text-white pargraph texgt-white '>28 different character and creature design.</p>
                    </div>
                    </div>
                    <div className=' w-1/2 text-right pt-5 px-2'>
                        <h1 className='text-[20px]  font-[pixel] leading-[21px] text-white text-normal '>
                        90% - NFT Liquidity Pool
                        </h1>
                     <p className='text-[14px] leading-[21px]  text-normal text-white pargraph texgt-white '>28 different character and creature design.</p>
                    </div>

                    <div className='flex justify-end' >
                    <div className=' w-1/2 text-left pt-5 pl-2'>
                        <h1 className='text-[18px] uppercase font-[pixel] leading-[21px] text-white text-normal '>
                        100% - NFT Breeding
                        </h1>
                     <p className='text-[14px] leading-[21px]  text-normal text-white pargraph texgt-white '>28 different character and creature design.</p>
                    </div>
                    </div>
                    <div className='mt-5 -z-10  left-0 top-0 right-0 absolute'>
                        <img className='mx-auto h-[35rem]' src={vectorline} alt="" />
                        <div className='absolute top-9 left-0 right-0'>
                            <h1 className='w-3 h-3 mx-auto bg-black border-black border text-white'></h1>
                        </div>
                        <div className='absolute top-9 left-0 right-0'>
                            <h1 className='w-3 h-3 mx-auto bg-black border-black border text-white'></h1>
                        </div>
                        <div className='absolute top-36 left-0 right-0'>
                            <h1 className='w-3 h-3 mx-auto bg-black border-black border text-white'></h1>
                        </div>
                        <div className='absolute top-[15.5rem] left-0 right-0'>
                            <h1 className='w-3 h-3 mx-auto bg-black border-black border text-white'></h1>
                        </div>
                        <div className='absolute bottom-[12.5rem] left-0 right-0'>
                            <h1 className='w-3 h-3 mx-auto bg-black border-black border text-white'></h1>
                        </div>
                        <div className='absolute bottom-[5.8rem] left-0 right-0'>
                            <h1 className='w-3 h-3 mx-auto bg-black border-black border text-white'></h1>
                        </div>
                        <div className='absolute bottom left-0 right-0'>
                            <h1 className='w-3 h-3 mx-auto bg-white  border text-white'></h1>
                        </div>
                    </div>

                </div>
               
                    


                    

            


            </div> */}
         
         
         
         
         
         
         
         
         
         

            {/* --------------------------------------------------------------------------Desktop view of Roadmap section----------------------------------------------------------------------- */}
            {/* <div data-aos="zoom-out"
                className='RoadmapScetion lg:pt-[8rem]    '>



                <div className='flex justify-center  md:px-24  md:py-10'>
                    <img src={image} alt='group50' />
                </div>


            </div> */}



        </>
    )
}

export default Hero
