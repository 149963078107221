import React from 'react'
import { useWeb3React } from "@web3-react/core";
import logo from "../images/star.png"
import detectEthereumProvider from '@metamask/detect-provider'


function NFTWalletBtn() {


    const { connector, account, activate } = useWeb3React();

    console.log(
        "connector", connector
    );

    const addTokenHandler = async()=> {
       
        try {
                  
            const provider = await detectEthereumProvider()
            provider.sendAsync({
              method: 'metamask_watchAsset',
              params: {
                "type": "ERC20",
                "options": {
                  "address": "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
                  "symbol": "WETH",
                  "decimals": 18,
                  "image": "https://pbs.twimg.com/profile_images/802481220340908032/M_vde_oi_400x400.jpg",
                },
              },
            })
        }    catch(e) {
            console.log("errorr", e);
        }
    }


  return (
    <div>
         <div  className="absolute  flex justify-center items-center text-white  -top-5 left-0 right-0     " >
     <button onClick={() => addTokenHandler()} className="text-center  align-middle md:px-20 md:py-5 py-2 px-3 items-center   w-fit  bg-red-600 font-[pixel] md:text-[30px]   leading-5 text-normal ">
     NFT Wallet
     </button>

    </div>
    </div>
  )
}

export default NFTWalletBtn
