import React, { useState } from 'react'
import faq from '../images/Group 39.png'
import footer from '../images/Group 17.png'
import uperrShadow from '../images/image6.png'
import discord from '../images/discord.png'
import telegram from '../images/telegram.png'
import twitter from '../images/twitter.png'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import blackrectangle from '../images/Rectangle 97.png'
import TelegramIcon from '@mui/icons-material/Telegram';
import TwitterIcon from '@mui/icons-material/Twitter';
import rectangledot from '../images/Group 63.png';
import FAQrectangle from '../images/FAQ.png';
import FacebookIcon from '@mui/icons-material/Facebook';
import {FaDiscord} from "react-icons/fa";
import NFTWalletBtn from './NFTWalletBtn'


function FAQ_section() {
  
    return (
        <>

            {/*   for desktop view faq section */}

            <div className='FAQ relative bg-[#EF2938]   w-full     '>
            {/* <NFTWalletBtn/> */}
                <img  className=' w-full ' src={uperrShadow} alt="eclipse" />
                <div className=''>
                    <div className='absolute xl:top-40 lg:top-12 top-0   left-0 right-0  '>
                        <img className='mx-auto ' src={rectangledot} alt="group63" />

                    </div>
                    <div className='absolute flex justify-center left-0 right-0   lg:top-16  xl:top-[12rem] '>
                        <h1 className='  lg:text-[56px] xl:text-[96px] font-normal leading-[102.px] text-white  uppercase font-[pixel]'>FAQ</h1>
                    </div>


                    <div className='absolute xl:top-[20rem] top-20   md:px-16  lg:top-[12rem] left-0 right-0 xl:px-52 lg:bg-[#1E1E1E]  lg:w-full px-5  ' >
                        <div className='bg-[#1E1E1E]   lg:w-full   '>
                            <Accordion sx={{ backgroundColor: "inherit", borderTop: "none", borderRadius: "0px", borderBottom: "2px solid white", }} >
                                <AccordionSummary

                                    expandIcon={<ExpandMoreIcon className='text-white' />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <div className='text-white xl:text-lg lg:text-sm text-[12px] '>
                                        <Typography  >
                                            <div className='text-sm  text-white lg:text-lg xl:text-lg '>
                                                Where are the images for the punks stored?
                                            </div>
                                        </Typography>


                                    </div>
                                </AccordionSummary>


                                <AccordionDetails>
                                    <Typography className='text-white'>

                                        <div className='text-xs  text-white  xl:text-sm lg:text-sm'>
                                            On IPFS, with IPFS, we get the benefits of decentralized, immutable and verifiable storage immediately.
                                            We can seamlessly push content into IPFS to create immutable links to the NFT's metadata and ensure the content is stored in perpetuity.
                                        </div>
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        </div>
                        <div className=' bg-[#1E1E1E]  w-full '>
                            <Accordion sx={{ backgroundColor: "inherit", borderTop: "none", borderLeft: "none", borderRadius: "0px", borderBottom: "2px solid white", }} >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon className='text-white' />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography className='text-white  xl:text-lg lg:text-sm text-xs ' >
                                        <div className='text-sm lg:text-lg xl:text-lg'>
                                            Are the punks an ERC-721 token?

                                        </div>

                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        <div className='text-xs  lg:text-sm xl:text-sm text-white '>


                                            Yes. RecoveryPunks are ERC-721 and comply with ERC-721 standards.
                                            This means that they sit in the Ethereum network and can be viewed on any ERC-721 marketplace if you want to view your RecoveryPunks
                                        </div>
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        </div>
                        <div className=' bg-[#1E1E1E] w-full '>
                            <Accordion sx={{ backgroundColor: "inherit", borderTop: "none", borderRadius: "0px", borderBottom: "2px solid white", }} >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon className='text-white' />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography className=' text-white xl:text-lg lg:text-sm text-xs ' >

                                        <div className='text-sm lg:text-lg xl:text-lg'>
                                            Do you charge any fees for transactions?
                                        </div>
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography className='  text-white xl:text-lg lg:text-sm text-xs '>

                                        <div className='text-xs  lg:text-sm xl:text-sm  text-white'>
                                        No, we do not charge any fees for the transactions on RecoveryPunks. There is a 10% royalty on every sell until the entire collection is minted, in which case the royalties will be dropped to 2.5%. These royalties go directly to Artists for Addicts to fund addiction recovery and connection research. There may be transaction fees charged in Ethereum (gas for the transaction).
                                        </div>
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        </div>
                        {/* -------------------------------------------fotter for the mobile view ------------------------------------------------------------  */}
                        <div className=' md:hidden '>
{/*
                            <a target="/blank" href="http:web.telegram.org/?legacy=1#/login">
                            <div className='hover:scale-125 ease-linear duration-300 hover:duration-300 hover:ease-linear'>
                                <TelegramIcon className='text-white' />
                            </div>

                            </a> */}
<div className='flex bg-[#1E1E1E]  items-center justify-center   gap-5 '>


                             <a  target="/blank" href="https://discord.com/invite/JjyEEGXqGX">
                            <div className='hover:scale-125 ease-linear duration-300 hover:duration-300 hover:ease-linear'>
                           <img  className='w-25 h-25' src={discord} alt="" />
                            </div>

                             </a>


                            <a target="/blank" href="https://twitter.com/recoverypunks">
                            <div className='hover:scale-125 ease-linear duration-300 hover:duration-300 hover:ease-linear'>
                                <TwitterIcon className='text-white' />
                            </div>

                            </a>
                            </div>

                            <div className='text-white text-center  tracking-widest'>
                                <a  target="/blank" href="http://RecoveryPunks.com/">
                                RecoveryPunks.com
                                </a>
                            </div>

                        </div>

                        {/*-------------------------------------------------------- fotter for the desktop view--------------------------------------------------------------- */}

                        <div className='fotter   bg-[#1E1E1E]  w-full gap-5 pt-5  xl:pt-[4rem]  lg:pt-[2rem]'>

                            {/* <a target="/blank" href="http://web.telegram.org/?legacy=1#/login">
                                <div className='hover:scale-125 ease-linear duration-300 hover:duration-300 hover:ease-linear'>

                               <TelegramIcon className='text' />
                                </div>
                            </a> */}
<div className='flex justify-center items-center gap-5'>
<a target="/blank" href="https://twitter.com/recoverypunks">

<div className='hover:scale-125 ease-linear duration-300 hover:duration-300 hover:ease-linear '>
   <TwitterIcon sx={{fontSize:"48px"} } className='text-white text-3xl' />
</div>
</a>

                            <a target="/blank" href="https://discord.com/invite/JjyEEGXqGX">

                                <div  className='hover:scale-125 ease-linear duration-300 hover:duration-300 hover:ease-linear'>
                                  <FaDiscord className='text-white  text-[48px] ' />
                                </div>
                            </a>

                           
                            </div>
                            <div className='text-white text-center pt-5 text-2xl tracking-widest'>
                                <a target="/blank" href="http://RecoveryPunks.com/">
                                RecoveryPunks.com
                                </a>
                            </div>



                        </div>

                    </div>
                </div>

            </div>


            {/* { for tablet  } */}



            <div className='relative lg:hidden overflow-y-visible  w-full  '>

                {/* <NFTWalletBtn/> */}
               <img  className='bg-[#EF2938]' src={uperrShadow} alt="" />
                <div className=' border-2 border-yellow-500'>
                    <div className='absolute top-5 md:top-5  left-0 right-0  '>
                        <img className='mx-auto ' src={rectangledot} alt="group63" />

                    </div>
                    <div className='absolute flex justify-center  left-0 right-0  md:top-12  text-[30px]'>
                        <h1 className='  lg:text-[56px] xl:text-[96px] font-normal leading-[102.px] text-white  uppercase font-[pixel]'>FAQ</h1>
                    </div>


                    <div className='absolute top-20 md:top-32 left-0 right-0  px-8  bg-[#1E1E1E]' >
                        <div className=''>
                            <Accordion sx={{ backgroundColor: "inherit", borderTop: "none", borderRadius: "0px", borderBottom: "2px solid white", }} >
                                <AccordionSummary

                                    expandIcon={<ExpandMoreIcon className='text-white' />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <div className='text-white xl:text-lg lg:text-sm text-[12px] '>
                                        <Typography  >
                                            <div className='text-[12px]  text-white lg:text-lg xl:text-lg '>
                                                Where are the images for the punks stored?
                                            </div>
                                        </Typography>


                                    </div>
                                </AccordionSummary>


                                <AccordionDetails>
                                    <Typography className='text-white'>

                                        <div className='text-[8px]  text-white  '>
                                            On IPFS, with IPFS, we get the benefits of decentralized, immutable and verifiable storage immediately.
                                            We can seamlessly push content into IPFS to create immutable links to the NFT's metadata and ensure the content is stored in perpetuity.
                                        </div>
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        </div>
                        <div className=' bg-[#1E1E1E]  w-full '>
                            <Accordion sx={{ backgroundColor: "inherit", borderTop: "none", borderLeft: "none", borderRadius: "0px", borderBottom: "2px solid white", }} >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon className='text-white' />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography className='text-white  xl:text-lg lg:text-sm text-xs ' >
                                        <div className='text-[12px] lg:text-lg xl:text-lg'>
                                            Are the punks an ERC-721 token?

                                        </div>

                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        <div className='text-[8px]  text-white '>


                                            Yes. RecoveryPunks are ERC-721 and comply with ERC-721 standards.
                                            This means that they sit in the Ethereum network and can be viewed on any ERC-721 marketplace if you want to view your RecoveryPunks
                                        </div>
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        </div>
                        <div className=' bg-[#1E1E1E] w-full '>
                            <Accordion sx={{ backgroundColor: "inherit", borderTop: "none", borderRadius: "0px", borderBottom: "2px solid white", }} >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon className='text-white' />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography className=' text-white xl:text-lg lg:text-sm text-xs ' >

                                        <div className='text-[12px] lg:text-lg xl:text-lg'>
                                            Do you charge any fees for transactions?
                                        </div>
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography className='  text-white xl:text-lg lg:text-sm text-xs '>

                                        <div className='text-[8px]  lg:text-sm xl:text-sm  text-white'>
                                        No, we do not charge any fees for the transactions on RecoveryPunks. There is a 10% royalty on every sell until the entire collection is minted, in which case the royalties will be dropped to 2.5%. These royalties go directly to Artists for Addicts to fund addiction recovery and connection research. There may be transaction fees charged in Ethereum (gas for the transaction).
                                        </div>
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        </div>
                        {/* -------------------------------------------fotter for the tablets------------------------------------------------------------  */}
                        <div className='bg-[#1E1E1E]  w-full  p-5 gap-5    '>
                        {/* <div className='hover:scale-125 ease-linear duration-300 hover:duration-300 hover:ease-linear'>
                            <a target="/blank" href="http://web.telegram.org/?legacy=1#/login">
                                <TelegramIcon className='text-white' />
                            </a>

                        </div> */}


                        <div className=' flex items-center gap-2 md:gap-5 justify-center'>
                            <a target="/blank" href="https://twitter.com/recoverypunks">
                            <div className='hover:scale-125 ease-linear duration-300 hover:duration-300 hover:ease-linear'>
                                <TwitterIcon  sx={{fontSize:"28px"}} className='text-white' />
                            </div>
                            </a>

                            <a target="/blank" href="https://discord.com/invite/JjyEEGXqGX">
                            <div className='hover:scale-125 ease-linear duration-300 hover:duration-300 hover:ease-linear'>
                            <FaDiscord className='text-white  text-[28px] ' />
                            </div>
                           </a>
                        </div>


                        <div className='text-white text-center pt-5  tracking-wide'>
                            <a target="/blank"  href="http://RecoveryPunks.com/">
                                RecoveryPunks.com
                            </a>
                        </div>
                        </div>
                    </div>



                </div>

            </div>



        </>
    )
}

export default FAQ_section;
