import React,{useEffect} from 'react'
import star from "../images/star.png";
import image5 from "../images/Group 65.png";


import AOS from "aos";
import "aos/dist/aos.css";
import image6 from "../images/image6.png";
import image7 from '../images/MiddleReactangle.png';
import blackrectangle from '../images/Rectangle 97.png'
import blackrectangle21 from '../images/Rectangle 21.png'
import FAQrectangle from '../images/FAQ.png';
import image8 from "../images/image7.png";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

//  Carosuel Images
import animate1 from "../images/2.png";
import animate2 from "../images/3.png";
import animate3 from "../images/4.png";
import animate4 from "../images/7.png";
import animate5 from "../images/8.png";
import animate6 from "../images/9.png";
import animate7 from "../images/22.png";
import animate8 from "../images/24.png";
import animate9 from "../images/32.png";
import animate10 from "../images/35.png";
import animate11 from "../images/39.png";
import animate12 from "../images/43.png";
import animate13 from "../images/9048.png";
import animate14 from "../images/9053.png";
import animate15 from "../images/9058.png";
import animate16 from "../images/9060.png";
import animate17 from "../images/9061.png";
import animate18 from "../images/9066.png";
import animate19 from "../images/9053.png";
import animate20 from "../images/9055.png";
import animate21 from "../images/9058.png";
import animate22 from "../images/9060.png";
import animate23 from "../images/9061.png";

function Header() {
  useEffect(() => {

    AOS.init(
      {
        duration: 1500,
      }
    );
  }, [])


  return (
    <>
       <div   data-aos="" className='NavbarSection '>
       <div   className='flex     xl:py-2 inset-x-0  w-full justify-center items-center  rounded-b-full bg-white' >
       <h1 className="xl:text-5xl  text-sm uppercase font-[pixel] text-red-500">
            Recovery <span className="text-black"> punks </span>
            <span className="xl:text-lg lg:text-lg  text-xs text-black">
              by
            </span>{" "}
          </h1>
          <img
            className=" w-10 h-8  "
            data-aos=""
            src={star}
            alt="addicts"
          />
     <div   data-aos="" className='absolute top-0 -z-10'>
        <img src={image5} alt="backgroundImg" />
     </div>
       </div>
       </div>

{/* ------------------------------------------------------------------------black screen-------------------------- */}
<div className='relative  '>
       <div className='CurveSection md:mt-[15rem]  mt-[5rem] lg:mt-[25rem] xl:mt-[32rem]'>
       <img src={image6} alt="" />
       <img  className='w-full  ' src={blackrectangle} alt="" />
       <img className='bg-[#EF2938]' src={image8} alt="" />
       </div>
       
    <a  href="#unlock" className="absolute  flex justify-center items-center text-white  -top-5 left-0 right-0     " >
     <h1 className="text-center  hover:scale-110 hover:duration-100 duration-100 hover:ease-linear ease-linear align-middle md:px-20 md:py-5 py-2 px-3 items-center   w-fit  bg-red-600 font-[pixel] md:text-[30px]   leading-5 text-normal ">
     MINT NOW
     </h1>

    </a>

<div className="absolute xl:top-[20rem] md:top-52 top-12  lg:left-0 right-0  xl:w-full  ">

<div    className="text-center ">
  <h1 className="text-white  md:text-[45px]   lg:text-[56px] xl:text-[104px] leading-7 font-normal tracking-widest font-[pixel] ">RECOVERY  <span className="text-[#EF2938]  lg:text-[56px] font-normal font-[pixel]  xl:text-[104px] leading-7 ">PUNKS</span> </h1>
<p  className="text-[#FFFFFF] xl:px-72   px-2  text-xs xl:text-3xl  lg:text-lg mt-5 lg:mt-5 xl:mt-12  text-center   lg:text-center"
>
  Inspired by the original CryptoPunks project, the RecoveryPunks are 10,000 unique 24x24 pixel art images generated algorithmically. Anybody with an Ethereum wallet can claim a RecoveryPunks for free until all 10,000 are claimed. While they are all rare, some RecoveryPunks are rarer than others. 100% of mint profits and royalties go directly to funding addiction recovery and connection research.
</p>
</div>

</div>
</div>

{/* Carousel Section */}
<div className=' '>
       <h1
         
          className="absolute   xl:px-10  top-[20rem] lg:top-[62rem] xl:top-[88rem]  leading-[32px] md:top-[45rem]  font-[pixel] md:text-[30px]  w-full text-center  lg:text-[56px] xl:text-[65px] font-bold uppercase  text-red-500"
        >
          RECOVERY PUNKS{" "}
          <span
            
            className="text-white xl:text-[60px] font-bold lg:text-[56px]"
          >
            NFT
          </span>
        </h1>
        <Carousel className='absolute   top-[22rem] xl:top-[110rem]  lg:top-[72rem]  md:top-[50rem]' showStatus={false} showThumbs={false} showArrows={true} showIndicators={true} infiniteLoop='true' >
          <div className='flex  justify-center    '>
            <div className='space-y-2 xl:space-y-5 '>
              <img className=' ' src={animate1} alt="" />
              <img className='' src={animate2} alt="" />
              <img className='' src={animate3} alt="" />
            </div>
            <div className='space-y-2 xl:space-y-5'>
              <img className='' src={animate4} alt="" />
              <img className='' src={animate5} alt="" />
              <img className='' src={animate6} alt="" />
            </div>
            <div className='space-y-2 xl:space-y-5'>
              <img className='' src={animate7} alt="" />
              <img className='' src={animate8} alt="" />
              <img className='' src={animate9} alt="" />
            </div>
          </div>
          <div  className='flex justify-center '>
            <div className='space-y-2 xl:space-y-5'>
              <img className='' src={animate10} alt="" />
              <img className='' src={animate11} alt="" />
              <img className='' src={animate12} alt="" />
            </div>
            <div className='space-y-2 xl:space-y-5'>
              <img className='' src={animate13} alt="" />
              <img className='' src={animate14} alt="" />
              <img className='' src={animate15} alt="" />
            </div>
            <div className='space-y-2 xl:space-y-5'>
              <img className='' src={animate16} alt="" />
              <img className='' src={animate17} alt="" />
              <img className='' src={animate18} alt="" />
            </div>
          </div>
          <div  className='flex justify-center '>
            <div className='space-y-2 xl:space-y-5'>
              <img className='' src={animate19} alt="" />
              <img className='' src={animate20} alt="" />
              <img className='' src={animate21} alt="" />
            </div>
            <div className='space-y-2 xl:space-y-5'>
              <img className='' src={animate22} alt="" />
              <img className='' src={animate23} alt="" />
              <img className='' src={animate1} alt="" />
            </div>
            <div className='space-y-2 xl:space-y-5'>
              <img className='' src={animate2} alt="" />
              <img className='' src={animate3} alt="" />
              <img className='' src={animate4} alt="" />
            </div>
          </div>
        </Carousel>
</div>


{/* NFT Section */}
<div>

</div>

    </>
  )
}

export default Header